import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { addDoc, collection } from "@firebase/firestore";
import { firestore } from "../../firebase";
import "./Contact.css";
import { Link } from "react-router-dom";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const ref = collection(firestore, "ContactUs");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      message: "",
    },
  });
  const onSubmit = (data) => {
    let payload = JSON.stringify({
      sender: {
        name: "GrowX A.i",
        email: "info@thehomegrownpros.com",
      },
      to: [
        {
          name: `${data.fullName}`,
          email: `${data.email}`,
        },
      ],
      subject: "We've Received Your Inquiry!",
      htmlContent: `
      <!DOCTYPE html>
        <html lang="en">
          <head> </head>
          <body>
            <p>Dear ${data.fullName},</p>
            <p style="margin-bottom:8px">A warm welcome to the Growx A.i family!</p>
            <p style="margin-bottom:8px">
              Thank you for reaching out to Growx A.I. through our contact form. We've received your query and appreciate the opportunity to assist you.
            </p>
            <p style="margin-bottom:8px">
            Rest assured, we're diving into your request with enthusiasm and expertise. Our team is working diligently to provide you with the information or assistance you need.
            </p>
            <p style="margin-bottom:8px">
            At Growx A.I., we're committed to helping you grow your potential. So, let's growwww together!
            </p>
            <p style="margin-bottom:8px">
            We'll be in touch as soon as possible. In the meantime, feel free to explore our website or check out our latest updates on social media.
            </p>
            <p style="margin-bottom:8px"> 
            Thank you for choosing Growx A.I. We look forward to connecting with you soon!
            </p>
            <p style="margin-bottom:8px">Warm regards,</p>
            <p style="margin-bottom:8px">Growx A.I.</p>
          </body>
        </html>
      `,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.brevo.com/v3/smtp/email",
      headers: {
        accept: "application/json",
        "api-key":
          "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",
        "content-type": "application/json",
      },
      data: payload,
    };
    sendEmail(config, data);
  };

  async function sendEmail(config, grower) {
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        saveContact(grower);
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        setSuccess(true);
        setLoading(false);
      });
  }

  async function saveContact(data) {
    try {
      addDoc(ref, data);
    } catch (error) {
      throw error;
    }
  }

  if (success) {
    return (
      <div className="contact-us-page body-wrapper">
        <Container>
          <div className="row">
            <div className="col-lg-11 m-auto">
              <div className="contact-us-wrapper">
                <h1>Submitted Successfully!</h1>
                <p className="text-center pt-2">
                  Thank you for contacting Growx A.I.! 🌱
                  <br />
                  Your inquiry has been successfully submitted. We're excited to
                  help you growwww!
                  <br />
                  Our team will review your message and get back to you as soon
                  as possible. In the meantime, feel free to explore more about
                  Growx A.I. on our website. Have a great day!
                </p>
                <div className="row mt-4">
                  <div className="col-md-12 mb-4 text-center">
                    <Link to="/">
                      <button className="btn btn-submit">
                        Let's growwww! 🌱
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
  if (!error && !success) {
    return (
      <div className="contact-us-page body-wrapper">
        <Container>
          <div className="row">
            <div className="col-lg-10 col-xl-9 col-md-12 m-auto">
              <div className="contact-us-wrapper">
                <h1>Get In Touch</h1>
                <p>
                  Please complete the following information and a member of our
                  Customer <br className="d-md-block d-none" /> Service Team
                  will contact you
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <div className="form-group">
                        <label>Full Name</label>
                        <input
                          required
                          type="text"
                          placeholder="Enter your name"
                          className="form-control"
                          {...register("fullName", { required: true })}
                        />
                        {errors.fullName && (
                          <div className="invalid-feedback">
                            {errors.fullName.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 mb-4">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          required
                          type="email"
                          placeholder="Enter your email"
                          className="form-control"
                          {...register("email", {
                            required: true,
                            email: true,
                          })}
                        />
                        {errors.email && (
                          <div className="invalid-feedback">
                            {errors.email.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-4">
                      <div className="form-group">
                        <label>Message</label>
                        <textarea
                          rows="5"
                          placeholder="Enter your massage"
                          className="form-control"
                          {...register("message")}
                        ></textarea>
                        {errors.message && (
                          <div className="invalid-feedback">
                            {errors.message.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-4 text-center">
                      <button
                        type="submit"
                        className="btn btn-submit"
                        disabled={loading}
                      >
                        {!loading ? "SUBMIT" : "Submitting..."}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Contact;
