import { getAuth, onAuthStateChanged, signOut } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import HgpTermsConditionsContent from "../HgpTermsConditionsContent/HgpTermsConditionsContent";
import LearnMoreContent from "../LearnMoreContent/LearnMoreContent";
import TermsConditionsContent from "../TermsConditionsContent/TermsConditionsContent";
import RegisterForm from "../register/RegisterForm";
import "./header.css";

function Header() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [showLearnMoreModal, setLearnMoreModalShow] = useState(false);
  const [showTermsModal, setTermModalShow] = useState(false);
  const [showHgpTermsModal, setHgpTermModalShow] = useState(false);
  const [goBackRoute, setGoBackRoute] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  const history = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, async (userDetail) => {
      if (userDetail) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  const handleClose = () => {
    setGoBackRoute("");
    setRegisterModalShow(false);
  };
  const handleShow = () => setRegisterModalShow(true);

  const handleLearnMoreClose = () => {
    setGoBackRoute("");
    setLearnMoreModalShow(false);
  };
  const handleLearnMoreShow = () => setLearnMoreModalShow(true);

  const handleTermsClose = () => {
    setGoBackRoute("");
    setTermModalShow(false);
  };
  const handleTermsShow = () => setTermModalShow(true);

  const handleHgpTermsClose = () => {
    setGoBackRoute("");
    setHgpTermModalShow(false);
  };
  const handleHgpTermsShow = () => setHgpTermModalShow(true);

  const openRegisterModal = () => {
    handleLearnMoreClose();
    handleHgpTermsClose();
    handleTermsClose();
    handleShow();
  };

  const openTermConditionModal = () => {
    handleLearnMoreClose();
    handleTermsShow();
  };

  const openHgpTermConditionModal = () => {
    handleLearnMoreClose();
    handleHgpTermsShow();
  };

  const signOuNow = async () => {
    if (true) {
      try {
        const auth = getAuth();
        signOut(auth);
        setIsLoggedIn(false);
      } catch (error) {
        throw error;
      }
    }
  };

  const handleNavItemClick = () => {
    setExpanded(false); // Close the Navbar.Collapse when a Nav.Link is clicked
  };

  return (
    <>
      <Navbar expanded={expanded} expand="lg" className="navbar-dark">
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img
                src="/logos/growxAI-color.svg"
                width="160"
                height="54"
                className="d-inline-block align-top"
                alt="GrowX"
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          />
          <Navbar.Collapse className="justify-content-end">
            <Nav>
              <Nav.Link
                as={Link}
                className={location.pathname === "/" ? "active-nav" : ""}
                to="/"
                onClick={handleNavItemClick}
              >
                Home
              </Nav.Link>
              {/* <Nav.Link
                as={Link}
                className={location.pathname === "/about" ? "active-nav" : ""}
                to={"/about"}
                onClick={handleNavItemClick}
              >
                About
              </Nav.Link> */}
              <Nav.Link
                as={Link}
                className={
                  location.pathname === "/tutorials" ? "active-nav" : ""
                }
                to={"/tutorials"}
                onClick={handleNavItemClick}
              >
                Tutorial
              </Nav.Link>
              <Nav.Link
                as={Link}
                className={location.pathname === "/pricing" ? "active-nav" : ""}
                to={"/pricing"}
                onClick={handleNavItemClick}
              >
                Pricing
              </Nav.Link>
              {isLoggedIn == false ? (
                <Nav.Link onClick={handleShow}>Registration</Nav.Link>
              ) : (
                ""
              )}
            </Nav>

            <Dropdown autoClose="inside">
              <Dropdown.Toggle
                className="toggle"
                id="dropdown-autoclose-inside"
              >
                <Nav.Link className="ms-lg-1 ms-0 mt-lg-0 mt-2 ">
                  <img
                    src="/images/avatar.png"
                    className="img-fluid pointer"
                    width={30}
                    alt="LOGGED"
                  />
                </Nav.Link>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {isLoggedIn === true ? (
                  <Dropdown.Item
                    onClick={() => {
                      history("/login");
                    }}
                  >
                    Profile
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      history("/login");
                    }}
                  >
                    Sign In
                  </Dropdown.Item>
                )}
                {isLoggedIn === true ? (
                  <Dropdown.Item onClick={signOuNow}>Sign Out</Dropdown.Item>
                ) : (
                  ""
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <RegisterForm />
        </Modal.Body>
      </Modal>
      <Modal
        show={showLearnMoreModal}
        onHide={handleLearnMoreClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="learn-more-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleLearnMoreClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <LearnMoreContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
            openTermConditionModal={() => {
              openTermConditionModal();
              setGoBackRoute("true");
            }}
            openHgpTermConditionModal={() => {
              openHgpTermConditionModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showTermsModal} onHide={handleTermsClose} size="xl" centered>
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleTermsClose();
                handleLearnMoreShow();
                setGoBackRoute("");
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleTermsClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <TermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={showHgpTermsModal}
        onHide={handleHgpTermsClose}
        size="xl"
        centered
      >
        <Modal.Body
          className="terms-condition-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          {goBackRoute ? (
            <div
              className="go-back"
              onClick={() => {
                handleHgpTermsClose();
                handleLearnMoreShow();
              }}
            >
              <img src="./images/go-back.svg" className="" alt="close" />
            </div>
          ) : (
            ""
          )}
          <div className="close-modal" onClick={handleHgpTermsClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <HgpTermsConditionsContent
            openRegisterModal={() => {
              openRegisterModal();
              setGoBackRoute("true");
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
