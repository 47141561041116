import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import RegisterForm from "../../pages/register/RegisterForm";
import "./footer.css";
import { Link } from "react-router-dom";
import { isAndroid, isIOS } from "react-device-detect";

function Footer() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);

  const handleClose = () => setRegisterModalShow(false);
  const handleShow = () => setRegisterModalShow(true);

  const download = () => {
    if (isAndroid) {
      window.open(process.env.REACT_APP_ANDROID_APP);
    } else if (isIOS) {
      window.open(process.env.REACT_APP_IOS_APP);
    } else {
      window.open(process.env.REACT_APP_ANDROID_APP);
    }
  };

  return (
    <div>
      <footer className="text-center text-lg-start">
        <section className="">
          <div className="container text-center text-md-start pt-md-5 pt-4 pb-3">
            <div className="row mt-3">
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Company</h5>
                <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    Home
                  </Link>
                </p>
                {/* <p className="footer-link">
                  <Link to={"/about"} className="text-reset">
                    About
                  </Link>
                </p> */}
                <p className="footer-link">
                  <Link to={"/tutorials"} className="text-reset">
                    Tutorials
                  </Link>
                </p>
                {/* <p className="footer-link">
                  <Link to={"/blog"} className="text-reset">
                    Blog
                  </Link>
                </p> */}
                <p className="footer-link">
                  <Link to={"/pricing"} className="text-reset">
                    Pricing
                  </Link>
                </p>
                <p className="footer-link">
                  <Link onClick={handleShow} className="text-reset">
                    Registration
                  </Link>
                </p>
                <p className="footer-link">
                  <Link onClick={download} className="text-reset">
                    Download App
                  </Link>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Get Help</h5>
                <p className="footer-link">
                  <Link to={"/faq"} className="text-reset">
                    FAQ
                  </Link>
                </p>
                {/* <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    Investor Relations
                  </Link>
                </p> */}
                <p className="footer-link">
                  <Link to={"/contact-us"} className="text-reset">
                    Contact
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/pricing"} className="text-reset">
                    Subscriptions
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/privacy-policy"} className="text-reset">
                    Privacy Policy
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/terms-conditions"} className="text-reset">
                    Terms & Conditions
                  </Link>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Our Family</h5>
                <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    HGP
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    CloneX A.i
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    HgxTV
                  </Link>
                </p>
                <p className="footer-link">
                  <Link to={"/"} className="text-reset">
                    HgA
                  </Link>
                </p>
              </div>
              <div className="col-lg-3 col-md-4 mb-4">
                <h5 className="footer-heading">Follow us</h5>
                <div className="social-platforms">
                  <a
                    className="facebook"
                    href="https://www.facebook.com/growxai/"
                  >
                    <img src="./images/facebook.svg" alt="facebook" />
                  </a>
                  <a
                    className="instagram"
                    href="https://www.instagram.com/growxai?igsh=N3g3bTlybzhzaWU0&utm_source=qr"
                  >
                    <img src="./images/instagram.svg" alt="instagram" />
                  </a>
                  <a
                    className="youtube"
                    href="https://youtube.com/@hgxtv_?feature=shared"
                  >
                    <img src="./images/youtube.svg" alt="instagram" />
                  </a>
                  <a
                    className="linkedin"
                    href="https://www.linkedin.com/in/jerry-%E2%80%9Cjayd%E2%80%9D-dababneh-9b20191b0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                  >
                    <img src="./images/linkedin.svg" alt="instagram" />
                  </a>
                </div>
                <img
                  src="./images/logo.png"
                  alt="GrowX"
                  width="195px"
                  height="54px"
                />
              </div>
            </div>
          </div>
          <div className="text-center pb-4">
            <p className="mb-0 rights-reserved">
              @2024 Powered by: GrowX A.i Technologies LLC. All Rights
              Reserved. 
            </p>
          </div>
        </section>
      </footer>
      {/* <footer className="black">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-links pt-lg-0 pt-2 pb-2 pb-lg-0">
                <ul className="mb-2">
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="follow text-center pt-4 pt-lg-4">
                <div className="follow-us pb-md-0 pb-3">Follow us</div>
                <div className="social-links">
                  <ul className="mb-0">
                    <li>
                      <a
                        href="https://www.facebook.com/growxai/"
                        target="_blank"
                      >
                        <img src="./images/facebook.svg" alt="facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/growxai?igsh=N3g3bTlybzhzaWU0&utm_source=qr"
                        target="_blank"
                      >
                        <img src="./images/instagram.svg" alt="instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://youtube.com/@hgxtv_?feature=shared"
                        target="_blank"
                      >
                        <img src="./images/youtube.svg" alt="youtube" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/jerry-%E2%80%9Cjayd%E2%80%9D-dababneh-9b20191b0?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
                        target="_blank"
                      >
                        <img src="./images/linkedin.svg" alt="youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pt-4 d-flex app-images justify-content-lg-center justify-content-center">
                <img
                  src="./images/google-play.svg"
                  alt="google-play"
                  className="img-fluid pointer"
                  onClick={handleShow}
                />
                &nbsp; &nbsp; &nbsp; &nbsp;
                <img
                  src="./images/app-store.svg"
                  alt="app-store"
                  className="img-fluid pointer"
                  onClick={handleShow}
                />
              </div>
              <p className="all-rights mb-0 pt-4 text-center">
                © 2024 Powered By: GrowX A.i Technologies, LLC. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </footer> */}
      <Modal show={showRegisterModal} onHide={handleClose} size="xl" centered>
        <Modal.Body
          className="register-modal"
          style={{
            backgroundImage: `url("./images/bg-logo.svg")`,
          }}
        >
          <div className="close-modal" onClick={handleClose}>
            <img src="./images/close.svg" className="" alt="close" />
          </div>
          <RegisterForm />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Footer;
