import { onAuthStateChanged } from "@firebase/auth";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "../../firebase";
import "./SignUp.css";
import RegisterForm from "../register/RegisterForm";

function SignUp() {
  const [loadingPage, setPageLoading] = useState(true);
  const [showSignUpForm, setshowSignUpForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (userDetail) => {
      if (userDetail) {
        navigate("/profile");
        setPageLoading(false);
      } else {
        setPageLoading(false);
        setshowSignUpForm(true);
      }
    });
  }, [auth]);

  return (
    <div className="auth-wrapper">
      {loadingPage ? (
        <div className="py-5 text-center my-5">loading...</div>
      ) : (
        <>
          <div>
            {showSignUpForm ? (
              <div className="registration-page">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-10 col-md-10 col-xl-10 m-auto align-self-center ">
                      <div className="registration-form-wrapper">
                        <div className="text-center">
                          <img
                            src="/favicon.ico"
                            width={120}
                            className="img-fluid mb-4"
                          />
                        </div>
                        <div className="section-heading">
                          <RegisterForm theme={"white"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default SignUp;
