import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "@firebase/auth";
import { doc, getDoc } from "@firebase/firestore";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../../firebase";
import Profile from "../Profile/Profile";
import RegisterForm from "../register/RegisterForm";
import "./login.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [showRegisterModal, setRegisterModalShow] = useState(false);
  const [userProfile, setUser] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setPageLoading] = useState(true);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (userDetail) => {
      if (userDetail) {
        if (!userProfile) {
          getUserDetails(userDetail);
        } else {
          navigate("/profile");
          setPageLoading(false);
        }
      } else {
        setPageLoading(false);
        setIsLoggedIn(false);
        setShowLoginForm(true);
        setShowProfile(false);
        setUser(null);
        navigate("/login");
      }
    });
  }, [auth]);

  const handleClose = () => {
    setRegisterModalShow(false);
  };
  const handleShow = () => {
    navigate("/register");
  };

  const getUserDetails = async (userDetail) => {
    const docRef = doc(firestore, "Grower", userDetail.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUser({ ...docSnap.data(), id: docSnap.id });
      setShowLoginForm(false);
      setShowProfile(true);
      setIsLoggedIn(true);
      navigate("/profile");
    } else {
      setIsLoggedIn(false);
      setShowLoginForm(true);
      setShowProfile(false);
      setUser(null);
      navigate("/login");
    }
    setPageLoading(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = async (data) => {
    setLoading(true);
    await signInWithEmailAndPassword(auth, data.email, data.password)
      .then((userCredential) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const errorCode = error.code;
        if (errorCode === "auth/wrong-password") {
          toast.error("Wrong Password!", {});
        }
        if (errorCode === "auth/user-not-found") {
          toast.error("User Not Found!");
        }
      });
  };

  const signOuNow = async () => {
    if (true) {
      try {
        const auth = getAuth();
        signOut(auth);
        setUser(undefined);
        setShowProfile(false);
        setIsLoggedIn(false);
        setShowLoginForm(true);
      } catch (error) {
        throw error;
      }
    }
  };

  return (
    <div className="auth-wrapper">
      <ToastContainer position="top-right" />
      {loadingPage ? (
        <div className="py-5 text-center my-5">loading...</div>
      ) : (
        <>
          <div>
            {showLoginForm && !isLoggedIn && !userProfile ? (
              <div className="login-page">
                <div className="container">
                  <div className="row">
                    {/* <div className="col-lg-6 col-md-4 col-xl-8 d-lg-block d-none">
                      <div className="promo-img">
                        <img src="/favicon.ico" className="img-fluid" alt="" />
                      </div>
                    </div> */}
                    <div className="col-lg-6 col-md-6 col-xl-6 m-auto align-self-center ">
                      <div className="login-form">
                        <div className="login-form-wrapper">
                          <div className="text-center">
                            <img
                              src="/favicon.ico"
                              width={120}
                              className="img-fluid mb-4"
                            />
                          </div>
                          <div className="welcome-back">
                            <h1>Welcome Back!</h1>
                          </div>
                          <div className="get-started">
                            <h3>Login to get started</h3>
                          </div>
                          <form
                            className="pt-3 pb-3"
                            onSubmit={handleSubmit(onSubmit)}
                          >
                            <div className="form-group mb-2">
                              <label>Email Address</label>
                              <input
                                type="email"
                                placeholder="Enter your Email here"
                                {...register("email", {
                                  required: "Email Address is required",
                                  email: true,
                                  pattern: {
                                    value:
                                      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message:
                                      "Please enter a valid email address",
                                  },
                                })}
                                className={
                                  errors.email
                                    ? "form-control invalid-input"
                                    : "form-control"
                                }
                                aria-invalid={errors.email ? "true" : "false"}
                              />
                              {errors.email && (
                                <div className="invalid-feedback">
                                  {errors.email.message}
                                </div>
                              )}
                            </div>
                            <div className="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                placeholder="Enter your Password here"
                                {...register("password", {
                                  required: "Password is required",
                                  email: true,
                                })}
                                className={
                                  errors.password
                                    ? "form-control invalid-input"
                                    : "form-control"
                                }
                                aria-invalid={
                                  errors.password ? "true" : "false"
                                }
                              />
                              {errors.password && (
                                <div className="invalid-feedback">
                                  {errors.password.message}
                                </div>
                              )}
                            </div>
                            <div className="text-center pt-4">
                              <button
                                className="btn btn-primary submit btn-sm py-2"
                                disabled={loading}
                              >
                                {!loading ? "Sign in" : "Signing in..."}
                              </button>
                            </div>
                            <div className="text-center">
                              <div className="dont-have-account text-center pt-4">
                                Don’t have an account ?{" "}
                                <a className="pointer" onClick={handleShow}>
                                  Get Registered
                                </a>
                              </div>
                              {/* <div className="or py-4">
                      <h4>- OR -</h4>
                    </div>
                    <div className="sign-with-google">
                      <button className="btn btn-light btn-lg">
                        <img src="./images/google.png" alt="GOOGLE" />
                        Sign in with Google
                      </button>
                    </div> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {showProfile && isLoggedIn && userProfile ? (
              <Profile user={userProfile} signOuNow={signOuNow} />
            ) : (
              ""
            )}
            <Modal
              show={showRegisterModal}
              onHide={handleClose}
              size="xl"
              centered
            >
              <Modal.Body
                className="register-modal"
                style={{
                  backgroundImage: `url("./images/bg-logo.svg")`,
                }}
              >
                <div className="close-modal" onClick={handleClose}>
                  <img src="./images/close.svg" className="" alt="close" />
                </div>
                <RegisterForm
                  closeRegisterModal={() => {
                    setRegisterModalShow(false);
                  }}
                />
              </Modal.Body>
            </Modal>
          </div>
        </>
      )}
    </div>
  );
}

export default Login;
