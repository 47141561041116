import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import "./about.css";

function About() {
  const [activeSection, setActiveSection] = useState("1");

  const openDownloadApp = (url) => {
    if (url) {
      window.open(url);
    }
  };

  return (
    <div
      className="about-us-page"
      style={{
        backgroundImage: `url("./images/about-us-hero.png")`,
      }}
    >
      <div className="container">
        <div className="about-us-page-wrapper">
          <div className="side-bar">
            <div className="links">
              <div
                className={activeSection == "1" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("1");
                }}
              >
                Who <br /> we are
              </div>
              <div
                className={activeSection == "2" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("2");
                }}
              >
                What is <br /> our mission
              </div>
              <div
                className={activeSection == "3" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("3");
                }}
              >
                our main <br /> features
              </div>
              <div
                className={activeSection == "4" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("4");
                }}
              >
                what we <br /> do exactly
              </div>
              <div
                className={activeSection == "5" ? "link active" : "link"}
                onClick={() => {
                  setActiveSection("5");
                }}
              >
                meet <br /> our team
              </div>
            </div>
          </div>
          <div className="about-us-content">
            {activeSection === "1" ? (
              <div className="section-01">
                <img
                  src="./images/we-are-growx-ai.svg"
                  alt="GrowXAI"
                  className="img-fluid"
                />
                <div className="app-icon-store-wrapper">
                  <img
                    onClick={() => {
                      openDownloadApp(process.env.REACT_APP_ANDROID_APP);
                    }}
                    src="./images/google-play.svg"
                    alt="google-play"
                    className="img-fluid pointer app-icon-store-img"
                  />
                  &nbsp; &nbsp;
                  <img
                    onClick={() => {
                      openDownloadApp(process.env.REACT_APP_IOS_APP);
                    }}
                    src="./images/app-store.svg"
                    alt="google-play"
                    className="img-fluid pointer  app-icon-store-img"
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "2" ? (
              <div className="section-02">
                <div className="row">
                  <div className="col">
                    <div className="our-mission">
                      <h2>Founded by xyz.</h2>
                      <p className="w-75 text-ligh">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="text-right">
                      <h1>$800 MM</h1>
                      <h2 className="text-color">In origination</h2>
                    </div>
                    <div className="text-right">
                      <h1>3x Growth</h1>
                      <h2 className="text-color">from 2016-2024</h2>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "3" ? (
              <div className="section-03">
                <div className="row">
                  <div className="col">
                    <div className="our-mission">
                      <h2>
                        FLorem ipsum <br />
                        dolor sit amet,{" "}
                      </h2>
                      <p className="w-75">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in
                        reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                        non proident, sunt in culpa qui officia deserunt mollit
                        anim id est laborum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "4" ? (
              <div className="section-04">
                <div className="row">
                  <div className="col-12">
                    <div className="our-mission">
                      <h2>FLorem ipsum dolor sit amet,</h2>
                      <div className="row mt-md-5 mt-4">
                        <div className="col-auto">
                          <img
                            src="./images/grow-log.png"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-4 col-md-5 align-self-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-lg-4 col-md-5 align-self-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                        <div className="col-auto">
                          <img
                            src="./images/grow-master.png"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-auto">
                          <img
                            src="./images/grow-tracking.png"
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-lg-4 col-md-5 align-self-center">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {activeSection === "5" ? (
              <div className="section-05">
                <div className="row">
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="team-wrapper">
                      <div className="avatar">
                        <img src="./images/team-avatar.png" />
                      </div>
                      <div className="team-info">
                        <div className="name">John doe</div>
                        <div className="title">Director</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
