import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import MapPage from "../MapPage/MapPage";
import { firestore } from "../../firebase";

function GrowTrack() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    const querySnapshot = await getDocs(collection(firestore, "Tech"));
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({
        id: doc.id,
        type: "Grow Track",
        ...doc.data(),
      });
    });
    setUsers(data);
  };

  return (
    <div>
      <MapPage users={users} />
    </div>
  );
}

export default GrowTrack;
