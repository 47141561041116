import React from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import "./start-growing.css";

function StartGrowing() {
  var days = [...Array(25).keys()].map((index) => index + 1);
  return (
    <div className="start-growing">
      <div
        className="hero"
        style={{ backgroundImage: `url("./images/start-growing-hero.jpeg")` }}
      >
        <Container>
          <div className="hero-content">
            <h1 className="from-seeds-to">
              <span className="mx-3">From </span>{" "}
              <span className="text-theme"> seeds </span>{" "}
              <span className="mx-3">to</span>
            </h1>
          </div>
        </Container>
      </div>
      <div className="amazing-features-section">
        <Container fluid>
          <Row>
            <Col lg={4}>
              <div
                className="feature"
                style={{
                  backgroundImage: `url("./images/features/feature-1.png")`,
                }}
              >
                <div className="feature-text">
                  Grow <span>Log</span>
                </div>
              </div>
              <div className="feature-footer-text">
                <p>
                  Elevate your <br /> gardening process <br /> with GrowLog
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div
                className="feature"
                style={{
                  backgroundImage: `url("./images/features/feature-2.png")`,
                }}
              >
                <div className="feature-text">
                  Grow <span>Master</span>
                </div>
              </div>
              <div className="feature-footer-text">
                <p>
                  Unlock your plant's full <br /> potential with GrowMaster's{" "}
                  <br />
                  personalized support for <br /> premium subscribers.
                </p>
              </div>
            </Col>
            <Col lg={4}>
              <div
                className="feature"
                style={{
                  backgroundImage: `url("./images/features/feature-3.png")`,
                }}
              >
                <div className="feature-text">
                  Grow <span>Tracking</span>
                </div>
              </div>
              <div className="feature-footer-text">
                <p>
                  Elevate plant care <br /> with Grow Tracking: <br /> Monitor,
                  optimize, <br /> Thrive
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="selected-feature-section">
        <div className="section-heading container">
          <h1>Grow Log</h1>
        </div>
        <Container>
          <Row>
            <Col lg={4}>
              <div className="growx-feature">
                <img src="./images/email-feature.png" className="img-fluid" />
              </div>
            </Col>
            <Col lg={4}>
              <div className="growx-feature">
                <img src="./images/video-feature.png" className="img-fluid" />
              </div>
            </Col>
            <Col lg={4}>
              <div className="growx-feature">
                <img
                  src="./images/live-chat-feature.png"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="plant-data-section">
        <div className="section-heading container">
          <h1>Plant Data</h1>
        </div>
        <Container className="mb-5">
          <Row className="mb-2">
            <Col md={8} xs={6}>
              <h2 className="environment">Environment</h2>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <h2 className="high">High</h2>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <h2 className="low">Low</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} xs={6} className="align-self-center">
              <h3 className="env-type">Temperature</h3>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} xs={6} className="align-self-center">
              <h3 className="env-type">Humidity %</h3>
            </Col>
            <Col md={2} xs={3} className="text-md-center">
              <input type="text" className="env-value" />
            </Col>
            <Col md={2} xs={3} className="text-md-center">
              <input type="text" className="env-value" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} xs={6} className="align-self-center">
              <h3 className="env-type">CO2 PPM</h3>
            </Col>
            <Col md={2} xs={3} className="text-md-center">
              <input type="text" className="env-value" />
            </Col>
            <Col md={2} xs={3} className="text-md-center">
              <input type="text" className="env-value" />
            </Col>
          </Row>
        </Container>
        <Container className="mb-5">
          <Row className="mb-2">
            <Col md={8} xs={6}>
              <h2 className="environment">Feeding</h2>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <h2 className="high">High</h2>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <h2 className="low">Low</h2>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} xs={6} className="align-self-center">
              <h3 className="env-type">Water volume</h3>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} xs={6} className="align-self-center">
              <h3 className="env-type">Water pH</h3>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md={8} xs={6} className="align-self-center">
              <h3 className="env-type">Water Temp</h3>
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
            <Col md={2} xs={3} className="text-center">
              <input type="text" className="env-value" />
            </Col>
          </Row>
        </Container>

        <Container className="mb-5">
          <Row className="mb-2">
            <Col md={8} xs={12}>
              <img src="./images/Video + Overlay 1.png" className="img-fluid" />
            </Col>
            <Col md={4} xs={12} className="align-self-center">
              <div className="p-md-5 p-4">
                <button className="btn btn-light btn-block btn-lg w-100 mb-md-5 mb-4">
                  Upload Picture
                </button>
                <button className="btn submit-btn btn-block btn-lg w-100">
                  Save
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="amazing-features-section">
        <Container>
          <Row>
            <Col lg={6}>
              <div
                className="feature"
                style={{
                  backgroundImage: `url("./images/features/feature-1.png")`,
                }}
              >
                <div className="feature-text">
                  Grow <span>Master</span> <br />
                  on demand
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div
                className="feature"
                style={{
                  backgroundImage: `url("./images/features/feature-2.png")`,
                }}
              >
                <div className="feature-text">
                  Grow <span>Tech</span> <br />
                  on demand
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="growx-service-section pt-5 pb-5">
        <Container>
          <div className="row">
            <div className="col-md-8 m-auto">
              <div className="growx-service">
                <div className="service-name">
                  <div className="check-box"></div>
                  <div className="name">Plant Care</div>
                </div>
                <div className="service-icon">
                  <img src="./images/plant-care.png" alt="PLANT" />
                </div>
              </div>
              <div className="growx-service">
                <div className="service-name">
                  <div className="check-box"></div>
                  <div className="name">Tech Care</div>
                </div>
                <div className="service-icon">
                  <img src="./images/tech-care.png" alt="PLANT" />
                </div>
              </div>
              <div className="growx-service">
                <div className="service-name">
                  <div className="check-box"></div>
                  <div className="name">Scheduling for Plants & Tech</div>
                </div>
                <div className="service-icon">
                  <img src="./images/schedule-service.png" alt="PLANT" />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      {/* Date selection scroll section */}
      <div className="container">
        <div className="day-select-scroll py-5">
          {days.map((day, index) => (
            <div className="day-box" key={index}>
              <div>{day}</div>
              <div>Mon</div>
            </div>
          ))}
        </div>
      </div>

      {/* Date selection scroll section */}
      <div className="container py-5">
        <div
          className="grower-form-wrapper"
          style={{ backgroundImage: `url("./images/grower-form-modal.png")` }}
        >
          <div className="row">
            <div className="col-md-7">
              <form>
                <div className="form-group mb-4">
                  <label>What type of plant is it?</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group mb-4">
                  <label>
                    Which stage of growth is your plant currently in? (e.g.,
                    seedling, flowering)
                  </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group mb-4">
                  <label>
                    What specific nutrients are you using for your plant?
                  </label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group mb-4">
                  <label>On which day did you plant your plant?</label>
                </div>
                <div className="form-group mb-4">
                  <label>
                    Which day of the week are you currently on in your plant's
                    growth?
                  </label>
                </div>
                <div className="form-group mb-4 text-center mt-5">
                  <button className="btn btn-lg btn-primary px-5">
                    Submit
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-5 align-self-center">
              <div className="py-5 text-center">
                <img src="./images/question-mark.png" alt="QUESTION MARK" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StartGrowing;
