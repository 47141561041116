import { doc, updateDoc } from "@firebase/firestore";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { auth, firestore } from "../../firebase";
import "./Profile.css";
import GrowTrack from "../../components/GrowTrack/GrowTrack";
import GrowMaster from "../../components/GrowMaster/GrowMaster";

function Profile({ user, signOuNow }) {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("account");
  // const [deleteRequest, setDeleteRequest] = useState(user.deleteRequest);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: {
      name: user.name,
      user_type: user.user_type,
      username: user.username,
      displayName: `${user.firstName} ${user.lastName}`,
      email: user.email,
      phone: user.phone,
    },
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const updateData = doc(firestore, "Grower", user.id);
      updateDoc(updateData, data);
      setTimeout(() => {
        setLoading(false);
        setSuccessMessage("Updated Successfully!");
      }, 2000);
      setTimeout(() => {
        setSuccessMessage("");
      }, 4000);
    } catch (e) {
      setSuccessMessage("");
      setLoading(false);
    }
  };

  // const deleteAccount = async () => {
  //   if (
  //     window.confirm(
  //       "Are you certain you wish to proceed with the request to delete your account?"
  //     )
  //   ) {
  //     try {
  //       try {
  //         const updateData = doc(firestore, "Grower", user.id);
  //         updateDoc(updateData, { ...getValues(), deleteRequest: true });
  //         setDeleteRequest(true);
  //         alert(
  //           "Your request has been received and will be re-viewed by the admin."
  //         );
  //       } catch (e) {}
  //     } catch (error) {
  //       throw error;
  //     }
  //   }
  // };

  return (
    <div className="profile-page">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 m-auto col-md-12 col-12">
            <div className="section-heading text-center pb-3 pt-5">
              <h1>Account</h1>
              <img
                src="/images/divider.svg"
                width={100}
                className="img-fluid m-auto mb-3"
              />
              <div className="profile-tabs">
                <div
                  className={
                    currentTab === "account"
                      ? "profile-tab active-tab"
                      : "profile-tab"
                  }
                  onClick={() => {
                    setCurrentTab("account");
                  }}
                >
                  Account info
                </div>
                {/* <div
                  className={
                    currentTab === "history"
                      ? "profile-tab active-tab"
                      : "profile-tab"
                  }
                  onClick={() => {}}
                >
                  History
                </div> */}
                {user.subscription &&
                user.subscription.type &&
                user.subscription.type !== "Basic" ? (
                  <>
                    <div
                      className={
                        currentTab === "growMaster"
                          ? "profile-tab active-tab"
                          : "profile-tab"
                      }
                      onClick={() => {
                        setCurrentTab("growMaster");
                      }}
                    >
                      Grow Master
                    </div>
                    <div
                      className={
                        currentTab === "growTrack"
                          ? "profile-tab active-tab"
                          : "profile-tab"
                      }
                      onClick={() => {
                        setCurrentTab("growTrack");
                      }}
                    >
                      Grow Tracking
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="profile-form-wrapper">
              {currentTab === "account" ? (
                <>
                  <form className="pt-3 pb-3">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            type="text"
                            {...register("name", {
                              required: "Name is required",
                            })}
                            className={
                              errors.name
                                ? "form-control invalid-input"
                                : "form-control"
                            }
                            aria-invalid={errors.name ? "true" : "false"}
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Username</label>
                          <input
                            readOnly
                            type="text"
                            {...register("username")}
                            className={
                              errors.username
                                ? "form-control invalid-input"
                                : "form-control"
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            type="email"
                            readOnly
                            {...register("email", {
                              required: "Email Address is required",
                              email: true,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Please enter a valid email address",
                              },
                            })}
                            className={
                              errors.email
                                ? "form-control invalid-input"
                                : "form-control"
                            }
                            aria-invalid={errors.email ? "true" : "false"}
                          />
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email.message}
                            </div>
                          )}
                          {auth.currentUser.emailVerified ? (
                            <div className="valid-feedback">
                              <img
                                width={"14px"}
                                src={"./images/checkmark-circle.svg"}
                              />
                              <i> &nbsp;Email is verified</i>
                            </div>
                          ) : (
                            <div className="invalid-feedback">
                              Email not verified
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Phone number</label>
                          <input
                            type="number"
                            {...register("phone", {
                              required: "Phone number is required",
                              minLength: {
                                value: 10,
                                message:
                                  "Phone number must be at least 10 digits long.",
                              },
                              maxLength: {
                                value: 10,
                                message:
                                  "Phone number must be max 10 digits long.",
                              },
                              pattern: {
                                value: /^[0-9]*$/,
                                message:
                                  "Phone number must contain only numeric characters.",
                              },
                            })}
                            className={
                              errors.phone
                                ? "form-control invalid-input"
                                : "form-control"
                            }
                            aria-invalid={errors.phone ? "true" : "false"}
                          />
                          {errors.phone && (
                            <div className="invalid-feedback">
                              {errors.phone.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="divider"></div>
                  <div className="linked-accounts">
                    <div className="row mb-3">
                      <div className="col align-self-center">
                        <h3 className="mb-0 fw-600">Subscription Plans</h3>
                        <p className="mb-0">
                          Choose your plan according to your needs
                        </p>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <div className="col align-self-center">
                        <p className="mb-0">Current Plan</p>
                        <h3 className="fw-600">
                          {user.subscription && user.subscription.type
                            ? user.subscription.type
                            : ""}
                          {user.subscription &&
                          user.subscription.amount === 0 ? (
                            <span className="text-color"> Free </span>
                          ) : (
                            <span className="text-color"> Paid </span>
                          )}
                        </h3>
                      </div>
                      <div className="col-auto align-self-center">
                        <div className="accounts mt-0">
                          <div className="google">
                            <button
                              className="btn btn-sm btn-primary pe-4 ps-4"
                              onClick={() => {
                                navigate("/pricing");
                              }}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="divider"></div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="text-center pt-3">
                      <button
                        type="submit"
                        className="btn btn-primary submit"
                        disabled={loading}
                      >
                        {!loading ? "Save Changes" : "Updating..."}
                      </button>
                      {successMessage ? (
                        <p className="success-message">{successMessage}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </>
              ) : (
                ""
              )}
              {currentTab === "growTrack" ? (
                <>
                  <GrowTrack />
                </>
              ) : (
                <></>
              )}
              {currentTab === "growMaster" ? (
                <>
                  <GrowMaster />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
