import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, firestore } from "../../firebase";
import Plan from "./Plan";
import "./pricing.css";

function Pricing() {
  const [userProfile, setUser] = useState(undefined);
  const [isLoading, setLoading] = useState(true);
  const [plansData, setPlansData] = useState([]);

  useEffect(() => {
    const data = [
      {
        id: 1,
        name: "Basic",
        type: "Free",
        price: 0,
        duration: "",
        currency: "USD",
        desc: "",
        buttonText: "Get Started",
        isActive: true,
        whatsIncluded: [
          {
            label: "Free Trail",
            value: true,
          },
          {
            label: "All Videos",
            value: true,
          },
          {
            label: "Plant Log",
            value: true,
          },
          {
            label: "Plant Care",
            value: false,
          },
          {
            label: "Tech Care",
            value: false,
          },
          {
            label: "Scheduling",
            value: false,
          },
          {
            label: "Email",
            value: false,
          },
          {
            label: "Live Chat",
            value: false,
          },
          {
            label: "Video Consultancy",
            value: false,
          },
        ],
      },
      {
        id: 2,
        name: "Standard",
        type: "$199/Year",
        price: 199,
        duration: "Year",
        currency: "USD",
        desc: "",
        buttonText: "Buy Now",
        isActive: false,
        whatsIncluded: [
          {
            label: "All Videos",
            value: true,
          },
          {
            label: "Plant Log",
            value: true,
          },
          {
            label: "Plant Care",
            value: true,
          },
          {
            label: "Tech Care",
            value: true,
          },
          {
            label: "Scheduling",
            value: true,
          },
          {
            label: "Emails",
            value: false,
          },
          {
            label: "Live Chat",
            value: false,
          },
          {
            label: "Video Consultancy",
            value: false,
          },
        ],
      },
      {
        id: 3,
        name: "Premium",
        type: "$299/Year",
        price: 299,
        duration: "Year",
        currency: "USD",
        desc: "",
        buttonText: "Buy Now",
        isActive: false,
        whatsIncluded: [
          {
            label: "All Videos",
            value: true,
          },
          {
            label: "Plant Log",
            value: true,
          },
          {
            label: "Plant Care",
            value: true,
          },
          {
            label: "Tech Care",
            value: true,
          },
          {
            label: "Technician On Demand",
            value: true,
          },
          {
            label: "4 Calls",
            value: true,
          },
          {
            label: "Emails",
            value: true,
          },
          {
            label: "Live Chat",
            value: true,
          },
          {
            label: "Video Consultancy",
            value: true,
          },
        ],
      },
    ];
    onAuthStateChanged(auth, async (userDetail) => {
      if (userDetail) {
        if (!userProfile) {
          getUserDetails(userDetail, data);
        } else {
          setLoading(false);
        }
      } else {
        setPlansData(data);
        setLoading(false);
      }
    });
  }, [auth]);

  const getUserDetails = async (userDetail, data) => {
    const docRef = doc(firestore, "Grower", userDetail.uid);
    const docSnap = await getDoc(docRef);
    setLoading(true);
    if (docSnap.exists()) {
      if (docSnap.data()) {
        const user = docSnap.data();
        if (user.subscription && user.subscription.type === "Basic") {
          data[0].isActive = true;
          data[0].buttonText = "Get Started";
        }
        if (user.subscription && user.subscription.type === "Standard") {
          data[1].isActive = true;
          data[0].isActive = false;
          data[1].buttonText = "Get Started";
        }
        if (user.subscription && user.subscription.type === "Premium") {
          data[2].isActive = true;
          data[0].isActive = false;
          data[2].buttonText = "Get Started";
        }
        setPlansData(data);
        setUser({ ...docSnap.data(), id: docSnap.id });
      }
      setLoading(false);
    } else {
      setPlansData(data);
      setUser(null);
      setLoading(false);
    }
  };

  return (
    <div className="pricing-page">
      <div className="text-center">
        <h1 className="heading">Our pricing plans</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur adipiscing elit{" "}
          <br className="d-md-block d-none" /> dolor posuere vel venenatis eu
          sit massa volutpat.
        </p>
      </div>
      {/* <div className="choose-currency">
        <p>Choose your currency</p>
        <select>
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
          <option value="GBP">GBP</option>
        </select>
      </div> */}
      {isLoading ? (
        <div className="text-center my-5 py-5 text-dark">loading...</div>
      ) : (
        <div className="plans mb-5 mt-5">
          <div className="container">
            {plansData && plansData.length ? (
              <div className="row">
                {plansData.map((plan, index) => {
                  return (
                    <div className="col-md-4" key={index}>
                      <Plan plan={plan} />
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Pricing;
